import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import Icon from "@identitybuilding/idb-react-iconlib";
import { useSelector } from "react-redux";

const Footer = (props) => {
  const [value, setValue] = useState("");
  let colors = useSelector((state) => state.AdminReducer.colors);
  const modules = useSelector((state) => state.AdminReducer.modules);
  const translate = useSelector((state) => state.AdminReducer.translate);
  const lang = useSelector((state) => state.AdminReducer.lang);
  const superplanner_employee = useSelector((state) => state.AdminReducer.superplanner_employee);

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  // };

  return (
    <footer
      className={props.theme}
      style={{
        background:
          props.theme === "dark"
            ? "var(--grey-90)"
            : props.theme === "main"
              ? colors.main
              : props.theme === "sub"
                ? colors.sub
                : props.theme === "gradient"
                  ? `linear-gradient(45deg, ${colors.sub} 0%, ${colors.main} 100%)`
                  : "var(--grey-10)",
      }}
    >
      <div className="topFooter">
      {superplanner_employee && modules.filter((e) => e.name === 'Superplanner')[0].langs.includes(lang) && <div className="footerLogin">
          <h2>{translate('login')}</h2>
          <ul>
            <li>
              <a href={superplanner_employee} target="_blank" className="login_superplanner">{lang === 'nl' ? 'AANMELDEN' : 'CONNEXION'}</a>
            </li>
          </ul>
        </div>}
        <div className="footerPrivacy">
          <h2>Privacy</h2>
          <ul>
            <li>
              <a target="_blank" href="/pages/policy">
                <Icon name="Locked" />
                Privacy Policy
              </a>
            </li>
            <li>
              <a target="_blank" href="/pages/disclaimer">
                <Icon name="Locked" />
                Disclaimer
              </a>
            </li>
            {/* <li><Link to="/terms"><Icon name="Locked" />Terms and Conditions</Link></li> */}
          </ul>
        </div>
        {/* <div className="footerEnterprise">
          <h2>{translate('our_details')}</h2>
          <ul>
            <li>
              <Icon name="Entrepreneur" />
              {props.name}
            </li>
            <li>
              <Icon name="Info" />
              {props.enterprise_name}
            </li>
            <li>
              <Icon name="Mapmarker" />
              {props.address}
            </li>
            <li>
              <Icon name="" /> {props.city}
            </li>
            {props.phone && (
              <li>
                <Icon name="Phone" />
                {props.phone}
              </li>
            )}
            {props.cellphone && (
              <li>
                <Icon name="Cellphone" />
                {props.cellphone}
              </li>
            )}
          </ul>
        </div> */}
        <div className="footerConnect">
          <h2>{translate('lets_connect')}</h2>
          {/* {props.contact && (
            <form
              id="contactForm"
              onSubmit={handleSubmit.bind(this)}
              method="post"
            >
              <textarea
                value={value}
                onChange={handleChange.bind(this)}
                placeholder="Let us know your message"
              />
              <input type="submit" value="Say hi!" />
            </form>
          )} */}
          <ul>
            {props.socialMedia.length > 0 &&
              props.socialMedia.map((res, index) => (
                res.name !== 'Mail' &&
                <li key={index}>
                  <a href={res.url} target="_blank" rel="noopener noreferrer">
                    <Icon name={res.icon} />
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
      {/* {props.sitemap && (
        <div className="siteMapFooter">
          <ul>
            {modules.map(
              (res, index) =>
                res.active && (
                  <li key={index}>
                    <Link
                      to={`?lang=${lang}#${res.name.replace(/\s/g, "").replace(/-/g, "")}`}
                    >
                      {translate(res.title)}
                    </Link>
                  </li>
                )
            )}
          </ul>
        </div>
      )} */}
      <div className="bottomFooter">
        {translate('concept_and_realisation_by')}&nbsp;
        <a href="https://identitybuilding.be">identityBuilding BV </a>
        &nbsp;{translate('created_for')}&nbsp;
        <a href={lang === 'nl' ? 'https://ondernemersnetwerk.be' : 'https://reseauentrepreneurs.be'}> {translate('the_entrepreneurs_network').charAt(0).toLowerCase() + translate('the_entrepreneurs_network').slice(1)}
        </a>

      </div>
    </footer>
  );
};

export default Footer;
