import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import { OsnAccordion } from '@identitybuilding/idb-react-ui-elements';
const BereideGerechten = () => {

    const { lang, translate, folder_name } = useSelector((state) => state.AdminReducer);

    return (
        <section id="bereideGerechten">
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Convergence&display=swap');
            </style>
            <h3>Bereide gerechten <br /> Ingrediënten en allergenen lijst</h3>
            <p className='intro'>De samenstelling van de maaltijd (allergenen) zal vermeld worden bij het product d.m.v een nummer. <b>BV. *1*5*12</b></p>


            <div className='container'>
                <aside>
                    <h4>Allergenen</h4>
                    <div className='icons'>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Eieren.png').default} alt="Eieren" />
                            </figure>
                            <span>1. Eieren</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Gluten.png').default} alt="Gluten" />
                            </figure>
                            <span>2. Gluten</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Lupine.png').default} alt="Lupine" />
                            </figure>
                            <span>3. Lupine</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Melk.png').default} alt="Melk" />
                            </figure>
                            <span>4. Melk</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Mosterd.png').default} alt="Mosterd" />
                            </figure>
                            <span>5. Mosterd</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Noten.png').default} alt="Noten" />
                            </figure>
                            <span>6. Noten</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Pinda.png').default} alt="Pinda's" />
                            </figure>
                            <span>7. Pinda's</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Schaaldieren.png').default} alt="Schaaldieren" />
                            </figure>
                            <span>8. Schaaldieren</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Selderij.png').default} alt="Selderij" />
                            </figure>
                            <span>9. Selderij</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Sesamzaad.png').default} alt="Sesamzaad" />
                            </figure>
                            <span>10. Sesamzaad</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Soja.png').default} alt="Soja" />
                            </figure>
                            <span>11. Soja</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Vis.png').default} alt="Vis" />
                            </figure>
                            <span>12. Vis</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Weekdieren.png').default} alt="Weekdieren" />
                            </figure>
                            <span>13. Weekdieren</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Rijst.png').default} alt="Rijst" />
                            </figure>
                            <span>14. Rijst</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Ajuin.png').default} alt="Ajuin" />
                            </figure>
                            <span>15. Ajuin</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Gist.png').default} alt="Gist" />
                            </figure>
                            <span>16. Gist</span>
                        </div>
                        {/* --------------------------------------------------- */}
                        <div className='item'>
                            <figure>
                                <img src={require('../library/images/allergenen/Allergenen-Zwavel.png').default} alt="Zwavel" />
                            </figure>
                            <span>17. Zwavel</span>
                        </div>
                        {/* --------------------------------------------------- */}
                    </div>
                </aside>
                <aside>
                    <OsnAccordion
                        elements={[
                            {
                                title: "1. Balletjes in tomatensaus:",
                                content:
                                    <p>
                                        Ingrediënten vlees + saus: Varkens- rundsgehakt, <b>PANEERMEEL *2, BLOEM *2, GIST *16,</b> suiker, glucose, <b>GLUTEN *2, EI *1</b>, zout, mout, olie, emulgator, lectine, bouillon, <b>BOTER *4,</b> tomatenpuree, gezeefde tomaten, smaakversterker E621, kruiden, zout, Madeirawijn, <b>UI *15</b>.
                                        <br /><br />
                                        Ingrediënten worteltjes+ erwtjes: Erwten, wortelen, zout.
                                        <br /><br />
                                        Ingrediënten puree: Aardappelen, volle <b>MELK *4, BOTER *4,</b> maltodextrine, gemodificeerd aardappelzetmeel, zout, <b>EIWIT *1,</b> stabilisator, dinatriumpyrofosfaat, dextrose, nootmuskaat, aroma, peperextract.
                                        <br /><br />
                                        Energie 345kj 82kcal per 100 gr.
                                    </p>
                            },
                            {
                                title: '2. Paardenworsten:',
                                content: <p>
                                    Ingrediënten vlees + saus: 50% paardenvlees, 48% varkenspek, specerijen, E621, <b>EIEREN *1, UI *15, MOSTERD *5,</b> tafelbier, zout, laurier, tijm, gemodificeerd zetmeel, bouillonpoeder, <b>RIJSTbloem *14,</b> plantaardig vetpoeder, <b>GISTextract *16,</b> tomatenpoeder, kleurstof E150c.
                                    <br /><br />
                                    Ingrediënten Appelcompote met stukken: appel, suiker, zuurteregelaar (citroenzuur), antioxidant (ascorbinezuur).
                                    <br /><br />
                                    Ingrediënten puree: Aardappelen, <b>volle MELK *4, BOTER *4,</b> maltodextrine, gemodificeerd aardappelzetmeel, zout, <b>EIWIT *1,</b> stabilisator, dinatriumpyrofosfaat, dextrose, nootmuskaat, aroma, peperextract.
                                    <br /><br />
                                    Energie 345kj 82kcal per 100 gr.
                                </p>
                            },
                            {
                                title: '3. Rundstong:',
                                content: <p>
                                    Ingrediënten vlees + saus: Rundstong, champignons, Madeirawijn, tomatenpuree, E621, zout, <b>MELKERIJBOTER *4, MARGARINE *4, BLOEM *2, UI *15,</b> kruiden, gezeefde tomaten.
                                    <br /><br />
                                    Ingrediënten puree:  aardappelen, volle <b>MELK *4, BOTER *4,</b> maltodextrine, gemodificeerd aardappelzetmeel, zout, <b>EIWIT *1,</b> stabilisator, dinatriumpyrofosfaat, dextrose, nootmuskaat, aroma, peperextract.
                                    <br /><br />
                                    Energie 345kj 82kcal per 100 gr.
                                </p>
                            },
                            {
                                title: '4. Tongrolletjes:',
                                content: <p>
                                    Ingrediënten vis + saus: Wijn, maiszetmeel, <b>TARWEbloem *2,</b> palmust, <b>UI *15,</b> bouillon, plantaardige olie, <b>KREEFT *8, MOSSELEN *13, KABELJAUW *12, TONG *12,</b> maltodextrine, groenten extract, suiker, zout, <b>GISTextract *16,</b> specerijen, champignons, conserveermiddelen, <b>UI *15,</b> peterselie, gepelde grijze <b>GARNALEN *8,</b> zout, voedingszuren (E330, E270), conserveermiddelen (E210)
                                    <br /><br />
                                    Ingrediënten puree: Aardappelen, volle <b>MELK *4, BOTER *4,</b> maltodextrine, gemodificeerd aardappelzetmeel, zout, <b>EIWIT *1,</b> stabilisator, dinatriumpyrofosfaat, dextrose, nootmuskaat, aroma, peperextract.
                                    <br /><br />
                                    Energie 345kj 82kcal per 100 gr.
                                </p>
                            },
                            {
                                title: '5. Veggieballetjes:',
                                content: <p>
                                    Ingrediënten balletjes: Gedehydrateerde <b>SOJA-eiwitten *11,</b> gedehydrateerde <b>TARWE-eiwitten *2,</b> mayonaise (zonnebloemolie, alcoholazijn, <b>EI-geel *1, MOSTERD *5,</b> zout, suiker), plantaardige oliën (koolzaad, zonnebloem), <b>EI-witpoeder *1,</b> alcoholazijn, <b>GISTextract *16, PANEERMEEL *2,</b> maïszetmeel, zout, <b>UIENPOEDER *15,</b> citroenvezels, specerijen, <b>GERSTemoutextract *2,</b> rode bietenpoeder, specerij-extracten, rozemarijn.
                                    <br /><br />
                                    Ingrediënten saus: Water, tomatenpuree, suiker, gemodificeerd maïszetmeel, palmvet ongehard, <b>UI *15,</b> zout, aroma, specerijen, voedingszuur (E330), stabilisatoren (E412, E415), conserveermiddelen (E211, E202), smaakversterker (E621), kruiden.
                                    <br /><br />
                                    Ingrediënten puree: Aardappelen, volle <b>MELK *4, BOTER *4,</b> maltodextrine, gedefeceerd aardappelzetmeel, zout, EIWIT <b>*1,</b> stabilisator, dinatriumpyrofosfaat, dextrose, nootmuskaat, aroma, peperextract.
                                    <br /><br />
                                    Energie 345kj 82kcal per 100 gr.
                                </p>
                            },
                            {
                                title: '6. Rundsstoofvlees:',
                                content: <p>
                                    Ingrediënten vlees + saus:  rundsvlees, ajuin, kruiden, zout, tafelbier, <b>BOTER *4, MOSTERD *5,</b> tijm, laurier, gemodificeerd zetmeel, bouillonpoeder, <b>RIJSTBLOEM *14,</b> plantaardig vetpoeder, <b>GISTextract *16,</b> E621, E150c, tomatenpoeder.
                                    <br /><br />
                                    Ingrediënten Appelcompote met stukken: appel, suiker, zuurteregelaar (citroenzuur), antioxidant (ascorbinezuur).
                                    <br /><br />
                                    Ingrediënten puree: Aardappelen, volle <b>MELK *4, BOTER *4,</b> maltodextrine, gemodificeerd aardappelzetmeel, zout, <b>EIWIT *1,</b> stabilisator, dinatriumpyrofosfaat, dextrose, nootmuskaat, aroma, peperextract.
                                </p>
                            },
                            {
                                title: '7. Vol-au-vent:',
                                content: <p>
                                    Ingrediënten vlees + saus:  kip, gehakt (varken-rund), champignons, <b>ROOM *4,</b> citroenzuur, E6, gevogeltebouillon, <b>MELKERIJBOTER *4, MARGARINE *4, BLOEM *2,</b> kruiden, zout, <b>SELDER *9</b>.
                                    <br /><br />
                                    Ingrediënten puree: Aardappelen, <b>volle MELK *4, BOTER *4,</b> maltodextrine, gemodificeerd aardappelzetmeel, zout, <b>EIWIT *1,</b> stabilisator, dinatriumpyrofosfaat, dextrose, nootmuskaat, aroma, peperextract.
                                </p>
                            },
                            {
                                title: '8. Vispannetje:',
                                content: <p>
                                    Ingrediënten vis + saus: Water, <b>ROOM *4, ZALM (14%) *12, KABELJAUW (14%) *12, SCHARfilet (14%) *12,</b> prei, <b>SELDER *9,</b> ui, witte wijn, gemodificeerd zetmeel, wortel, zonnebloemolie, maltodextrine, <b>SOJAhydrolisaat *11,</b> zout, <b>VISpoeder *12, GISTextract *16,</b> groente -kruiden- en specerijenextract (<b>SELDERIJ *9</b>), aroma (<b>VIS *12</b>), suiker, citroen, lookpoeder, dextrose, gelatine, specerijen, stabilisatoren:E407, E412, E466, E471, smaakversterkers: E621, E627, E631. Kan sporen bevatten van gluten, ei, mosterd, schaal – en weekdier.
                                    <br /><br />
                                    + Gepelde grijze <b>GARNALEN *8</b>
                                    <br /><br />
                                    Ingrediënten puree: Aardappelen, volle <b>MELK *4, BOTER *4,</b> maltodextrine, gemodificeerd aardappelzetmeel, zout, <b>EIWIT *1,</b> stabilisator, dinatriumpyrofosfaat, dextrose, nootmuskaat, aroma, peperextract.
                                </p>
                            },
                            {
                                title: '9. Zalm met bearnaise saus:',
                                content: <p>
                                    ingrediënten vis + saus: <b>ZALM *12,</b> water, raapolie, <b>MELKvet *4,</b> E337, E466, E407, E471, witte wijn, Eigeel, aroma’s E621- E627-E631, <b>TARWEvet *2,</b> zout, azijn, <b>MELKeiwit *4,</b> kleurstof E160a, specerijen, suiker, ui, E412, E410, E415, Worcestersaus, <b>SOJA eiwit *11,</b> E150c, E330, E202, <b>MOSTERD *5, MELK *4, EI *1, GLUTEN *2, SOJA *11</b>.
                                    <br /><br />
                                    + Gepelde grijze <b>GARNALEN *8</b>
                                    <br /><br />
                                    Ingrediënten puree: Aardappelen, volle <b>MELK *4, BOTER *4,</b> maltodextrine, gemodificeerd aardappelzetmeel, zout, <b>EIWIT *1,</b> stabilisator, dinatriumpyrofosfaat, dextrose, nootmuskaat, aroma, peperextract.
                                </p>
                            },
                        ]}
                        template={2}
                    />


                </aside>
            </div>


            <div className='info'>
                <h4>Heeft u nog vragen??</h4>
                <p>
                    U mag ons altijd contacteren op het nummer +32 52 41 16 95
                </p>
            </div>
        </section>
    )
}

export default BereideGerechten