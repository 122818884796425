import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import Icon from '@identitybuilding/idb-react-iconlib';

const Souffriau = () => {

    const { lang, translate, folder_name } = useSelector((state) => state.AdminReducer);

    return (
        <section className="souffriau">
            <div className="logo"></div>
            <h1>Bestel online!</h1>
            <p>Plaats je bestelling online en kom ze bij ons ophalen!</p>

            <a href="https://proxyleest.slagerij-online.be/nl/aanmelden.html" target="_blank">
                <Icon name="ArrowDoubleDown" />
            </a>
        </section>
    )
}

export default Souffriau