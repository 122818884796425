import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import Slider from "react-slick";

const LocalProducts = () => {

    var settings = {
        dots: true,
        arrows: true,
        slidesToShow: 7,
        slidesToScroll: 2,
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };
    const { translate, data } = useSelector((state) => state.AdminReducer);

    return (
        <section id="local_products">
            <h2>{translate('local_products')}</h2>
            <p>
                Saveurs et savoir-faire de chez nous, si proche et si bon! <br />
                Notre concept "Shop in the shop" vous permet de découvrir un large assortiment de produits belges provenant de la région.<br />
                Dans l'évolution constante des modes de consommation, nous mettons en avant le commerce de proximité et <br />
                le retour vers une alimentation gourmande et engagée.
            </p>
            <Slider {...settings}>

                {data.options.local_products.map(((res, index) => (
                    <div key={index} >
                        <a href={res.link} target="_blank">
                            <img title={res.name} src={res.image} alt={res.name} />
                        </a>
                    </div>
                )))}
            </Slider>
        </section>
    )
}

export default LocalProducts