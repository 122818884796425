import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import Icon from '@identitybuilding/idb-react-iconlib';

const OnzeTroeven = () => {

    const { lang, translate, folder_name } = useSelector((state) => state.AdminReducer);

    return (
        lang === 'nl' ?
            <section className="onzeTroeven">
                <div style={{ alignItems: "flex-start" }} className='troef_section'>
                    <h2>Onze troeven</h2>
                    <div className='troef_container'>
                        <span><Icon name="Dashboard" />Snel bediend</span>
                        <span><Icon name="Parking" />Ruime parking</span>
                        <span><Icon name="Coin" />Aankoop per flesje</span>
                        <span><Icon name="Eshop" />Ruim aanbod aan bieren en wijnen</span>
                        <span><Icon name="Restaurant" />Regelmatig degustaties (vooral in de weekends)</span>
                        <span><Icon name="Crown" />Originele en soms zelf ontworpen drankgeschenken</span>
                    </div>
                </div>
                <div className='troef_section spacer'><Icon name="Birthday" /></div>
                <div className='troef_section'>
                    <h2>Geeft u een feestje?</h2>
                    <p>Geeft u binnenkort een babyborrel, verjaardagsfeest of …? Kom dan zeker eens langs in een van onze winkels en vraag raad bij de winkelverantwoordelijke. Voor kleine feestjes opteren wij dat u dit zelf komt afhalen in de winkel.</p>
                    <p>Om snel geholpen te worden, denk je best al eens na over volgende zaken:</p>
                    <div className='troef_container'>
                        <span><Icon name="ArrowLongRight" />Soorten dranken die je wenst</span>
                        <span><Icon name="ArrowLongRight" />Datum wanneer het feest doorgaat</span>
                        <span><Icon name="ArrowLongRight" />Uw volledige adresgegevens en telefoonnummer</span>
                        <span><Icon name="ArrowLongRight" />Soorten materiaal (bakfrigo, glazen, … ) dat je wenst</span>
                        <span><Icon name="ArrowLongRight" />Datum wanneer je de dranken en het materiaal wenst af te halen</span>
                        <span><Icon name="ArrowLongRight" />Soort feest (babyborrel, …) + aantal aanwezige personen (opslitsing volwassenen en kinderen)</span>
                    </div>
                </div>
            </section>
            :
            <section className="onzeTroeven">
                <div style={{ alignItems: "flex-start" }} className='troef_section'>
                    <h2>Nos atouts</h2>
                    <div className='troef_container'>
                        <span><Icon name="Dashboard" />Rapidement opéré</span>
                        <span><Icon name="Parking" />Parking spacieux</span>
                        <span><Icon name="Coin" />Achat par bouteille</span>
                        <span><Icon name="Eshop" />Large gamme de bières et de vins</span>
                        <span><Icon name="Restaurant" />Dégustations régulières (surtout le week-end)</span>
                        <span><Icon name="Crown" />Cadeaux de boissons originaux et parfois fait-maison</span>
                    </div>
                </div>
                <div className='troef_section spacer'><Icon name="Birthday" /></div>
                <div className='troef_section'>
                    <h2>Vous organisez une fête ?</h2>
                    <p>Vous organisez une fête de bébé, un anniversaire ou… ? N’hésitez pas à passer dans l’un de nos magasins et à demander conseil au gérant. Pour les petites fêtes, nous optons pour que vous veniez le chercher vous-même au magasin.</p>
                    <p>Pour être aidé rapidement, il est préférable de penser à suivre les choses :</p>
                    <div className='troef_container'>
                        <span><Icon name="ArrowLongRight" />Date à laquelle la fête aura lieu</span>
                        <span><Icon name="ArrowLongRight" />Types de boissons que vous souhaitez</span>
                        <span><Icon name="ArrowLongRight" />Votre adresse complète et votre numéro de téléphone</span>
                        <span><Icon name="ArrowLongRight" />Types de matériel (frigo, verres, ...) que vous souhaitez</span>
                        <span><Icon name="ArrowLongRight" />Date à laquelle vous souhaitez venir chercher les boissons et le matériel</span>
                        <span><Icon name="ArrowLongRight" />Type de fête (baby shower, ...) + nombre de personnes présentes (en séparant adultes et enfants)</span>
                    </div>
                </div>
            </section>
    )
}

export default OnzeTroeven