import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import "../Superplanner/styles/Sollicitatie.css"
import script, {
    displayName,
    goToNext,
    goToPrev,
    showNextPrev,
    disableNextPrevButton,
    setHeight,
    fieldValidatorHandler,
    setBirthDateField,
    setVacationDatePeriod,
    telFormat,
    addPeriod,
    addPeriod2,
    blockTab,
    nameValidator,
    charValidator,
    emailValidator,
    emailRepeatValidator,
    blockPasteOnEmailRepeat,
    formatNumberBeforePaste,
    gsmValidator,
    telValidator,
    telNumberValidator,
    streetValidator,
    postalNumberValidator,
    geboorteplaatsValidator,
    idCardValidator,
    idCardValidatorFocus,
    idCardNumberValidator,
    rijksRegNrValidator,
    rijksRegNrValidatorFocus,
    rijksRegNrNumberValidator,
    workTimeValidator,
    radioJobStudentCheck,
    activateDeactivateVacation,
    datePeriodValidator,
    applicantAgreed,
    checkRequired,
    showPart,
    passwordReset,
    validatePart,
    activePart,
} from "../Superplanner/script/Script"

const Superplanner = () => {
    const { superplanner_link } = useSelector((state) => state.AdminReducer);
    const [postal_code, setPostal_code] = useState('');
    const [idCard, setIdCard] = useState('');
    const [rijksregister, setRijksregister] = useState('');

    const handlePostalCodeChange = (event) => {
        const value = event.target.value;
        // Remove non-numeric characters using a regular expression
        const newValue = value.replace(/[^0-9]/g, '');
        setPostal_code(newValue);
      };
      const handleIdCardChange = (event) => {
        const value = event.target.value;
        // Remove non-numeric characters using a regular expression
        const newValue = value.replace(/[^0-9]/g, '');
        setIdCard(newValue);
      };
      const handleRijksregisterChange = (event) => {
        const value = event.target.value;
        // Remove non-numeric characters using a regular expression
        const newValue = value.replace(/[^0-9]/g, '');
        setRijksregister(newValue);
      };
      
    useEffect(() => {
        script()
        setHeight(activePart)
    }, [])
    

    return (
        <section id="superplanner">
        {/* <section id="superplanner" onLoad={() => script} onResize={() => setHeight(activePart)}> */}
            <div id="container">
                <div id="jobAppForm">
                    <h1>Welkom <span id="applicant"></span>! Klaar om te solliciteren?</h1>
                    <h4>(Alle velden met een * zijn verplicht in te vullen)</h4>
                    <div className="jobAppFormView">
                        {/* <form method="post" action="https://www.superplanner.eu/adnederename/sollicitant.asp?Referal=OndernemersnetwerkM952"> */}
                        <form method="post" action={superplanner_link}>


                            {/* Alle mogelijke schrijfwijzes van referal of referer die ik ook 1 voor 1 geprobeerd heb */}
                            <input type="hidden" name="Referal" value="OndernemersnetwerkM952" id="Referal" />
                            <input type="hidden" name="referal" value="OndernemersnetwerkM952" id="referal" />
                            <input type="hidden" name="Referral" value="OndernemersnetwerkM952" id="Referral" />
                            <input type="hidden" name="referral" value="OndernemersnetwerkM952" id="referral" />

                            <input type="hidden" name="Referer" value="OndernemersnetwerkM952" id="Referer" />
                            <input type="hidden" name="referer" value="OndernemersnetwerkM952" id="referer" />
                            <input type="hidden" name="Referrer" value="OndernemersnetwerkM952" id="Referrer" />
                            <input type="hidden" name="referrer" value="OndernemersnetwerkM952" id="referrer" />

                            {/* Tab menu */}
                            <div className="viewTabs">
                                <div className="viewTab active">01 Vertel wat over jezelf</div>
                                <div className="viewTab">02 Identificatiegegevens</div>
                                <div className="viewTab">03 Werk en opleiding</div>
                                <div className="viewTab">04 Afronding</div>
                            </div>
                            <div id="view">

                                {/* PART 01 */}
                                <div className="part" id="part01">
                                    <div className="content">
                                        <h2><span>01</span>Vertel wat meer over jezelf</h2>
                                        <div className="partWrapper">
                                            <span className="voornaam input-50 input-item">
                                                <input
                                                    name="voornaam"
                                                    onBlur={() => nameValidator('voornaam')}
                                                    onKeyUp={() => displayName()}
                                                    onKeyPress={(event) => charValidator(event)}
                                                    placeholder="Voornaam*"
                                                    required
                                                    type="text"
                                                />
                                                <span className="voornaam-error error-message"></span>
                                            </span>
                                            <span className="achternaam input-50 input-item">
                                                <input
                                                    name="achternaam"
                                                    onBlur={() => nameValidator('achternaam')}
                                                    onKeyPress={(event) => charValidator(event)}
                                                    placeholder="Achternaam*"
                                                    required
                                                    type="text"
                                                />
                                                <span className="achternaam-error error-message"></span>
                                            </span>
                                            <span className="email1 input-50 input-item">
                                                <input
                                                    name="email1"
                                                    onBlur={() => emailValidator('email1')}
                                                    onKeyUp={() => emailRepeatValidator('email2')}
                                                    placeholder="Email*"
                                                    required
                                                    type="text"
                                                />
                                                <span className="email1-error error-message"></span>
                                            </span>
                                            <span className="email2 input-50 input-item">
                                                <input
                                                    name="email2"
                                                    onKeyUp={() => emailRepeatValidator('email2')}
                                                    placeholder="Herhaal email*"
                                                    required
                                                    type="text"
                                                />
                                                <span className="email2-error error-message"></span>
                                            </span>
                                            <span className="gsm input-50 input-item">
                                                <input
                                                    name="gsm"
                                                    onBlur={() => { gsmValidator('gsm'); telFormat('blur', 'gsm') }}
                                                    onFocus={() => telFormat('focus', 'gsm')}
                                                    onKeyPress={(event) => telNumberValidator(event, 'gsm')}
                                                    placeholder="GSM-nummer*"
                                                    required
                                                    type="text"
                                                />
                                                <span className="gsm-error error-message"></span>
                                            </span>
                                            <span className="tel input-50 input-item">
                                                <input
                                                    type="text"
                                                    name="tel"
                                                    onBlur={() => { telValidator('tel'); telFormat('blur', 'tel'); }}
                                                    onFocus={() => telFormat('focus', 'tel')}
                                                    onKeyPress={(event) => telNumberValidator(event, 'tel')}
                                                    placeholder="Telefoonnummer"
                                                />
                                                <span className="tel-error error-message"></span>
                                            </span>
                                            <span className="sub_title">Jij bent</span>
                                            <div className="radioGroup">
                                                <label id="jobstudent">
                                                    <input
                                                        // checked="checked"
                                                        className="internInput"
                                                        htmlFor="jobstudent"
                                                        name="werkenals"
                                                        onChange={() => radioJobStudentCheck()}
                                                        type="radio"
                                                        value="jobstudent"
                                                    />
                                                    <div className="radio">
                                                        <div className="radioFill"></div>
                                                    </div>
                                                    Jobstudent
                                                </label>
                                                <label id="interim">
                                                    <input
                                                        htmlFor="interim"
                                                        name="werkenals"
                                                        onChange={() => radioJobStudentCheck()}
                                                        type="radio"
                                                        value="interim"
                                                    />
                                                    <div className="radio">
                                                        <div className="radioFill"></div>
                                                    </div>
                                                    Interim / vast
                                                </label>
                                                <label id="flexijobber">
                                                    <input
                                                        htmlFor="flexijobber"
                                                        name="werkenals"
                                                        onChange={() => radioJobStudentCheck()}
                                                        type="radio"
                                                        value="flexijobber"
                                                    />
                                                    <div className="radio">
                                                        <div className="radioFill"></div>
                                                    </div>
                                                    Flexijobber
                                                </label>
                                            </div>
                                            <span className="ouders input-30 input-item" style={{ "display": "none" }}>
                                                <input
                                                    type="text"
                                                    name="ouders"
                                                    onBlur={() => { telValidator('ouders'); telFormat('blur', 'ouders') }}
                                                    onFocus={() => telFormat('focus', 'ouders')}
                                                    onKeyPress={(event) => telNumberValidator(event, 'ouders')}
                                                    placeholder="Tel. ouders of ICE (jobstudenten)*"

                                                />
                                                <span className="ouders-error error-message"></span>
                                            </span>

                                            {/* Address related fields */}
                                            <h3><span>Waar woon je?</span></h3>
                                            <span className="straat input-30 input-item">
                                                <input
                                                    name="straat"
                                                    onBlur={() => streetValidator('straat')}
                                                    placeholder="Straat + nummer*"
                                                    required
                                                    type="text"
                                                />
                                                <span className="straat-error error-message"></span>
                                            </span>
                                            <span className="postcode input-30 input-item">
                                                <input
                                                    name="postcode"
                                                    onBlur={() => streetValidator('postcode')}
                                                    onChange={(event) => {postalNumberValidator(event); handlePostalCodeChange(event)}}
                                                    placeholder="Postcode*"
                                                    value={postal_code}
                                                    required
                                                    type="text"
                                                    maxLength={4}
                                                />
                                                <span className="postcode-error error-message"></span>
                                            </span>
                                            <span className="gemeente input-30 input-item">
                                                <input
                                                    name="gemeente"
                                                    onBlur={() => streetValidator('gemeente')}
                                                    onKeyDown={(event) => blockTab(event)}
                                                    onKeyPress={(event) => charValidator(event)}
                                                    placeholder="Gemeente*"
                                                    required
                                                    type="text"
                                                />
                                                <span className="gemeente-error error-message"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {/* PART 02 */}
                                <div className="part" id="part02">
                                    <div className="content">
                                        <h2><span>02</span>Identificatiegegevens</h2>
                                        <div className="partWrapper">
                                            <span className="sub_title">Geboortedatum*</span>
                                            <div className="dateGroup birthdateGroup">
                                                <select name="geboortedag"></select>
                                                <select name="geboortemaand"></select>
                                                <select name="geboortejaar"></select>
                                            </div>
                                            <span className="geboorteplaats input-30 input-item">
                                                <input
                                                    name="geboorteplaats"
                                                    onBlur={() => geboorteplaatsValidator('geboorteplaats')}
                                                    onKeyPress={(event) => charValidator(event)}
                                                    placeholder="Geboorteplaats*"
                                                    required
                                                    type="text"
                                                />
                                                <span className="geboorteplaats-error error-message"></span>
                                            </span>
                                            <span className="IDKaartNr input-30 input-item">
                                                <input
                                                    name="IDKaartNr"
                                                    onBlur={() => idCardValidator('IDKaartNr')}
                                                    onFocus={() => idCardValidatorFocus()}
                                                    onKeyPress={(event) => idCardNumberValidator(event)}
                                                    onChange={(event) => handleIdCardChange(event)}
                                                    placeholder="Identiteitskaart nummer*"
                                                    required
                                                    value={idCard}
                                                    maxLength={12}
                                                    type="text"
                                                />
                                                <span className="IDKaartNr-error error-message"></span>
                                            </span>
                                            <span className="RijksRegisterNr input-30 input-item">
                                                <input
                                                    name="RijksRegisterNr"
                                                    onBlur={() => rijksRegNrValidator()}
                                                    onFocus={() => rijksRegNrValidatorFocus()}
                                                    onKeyPress={(event) => rijksRegNrNumberValidator(event)}
                                                    onChange={(event) => {blockTab(event); handleRijksregisterChange(event)}}
                                                    value={rijksregister}
                                                    // onChange={(event) => handleRijksregisterChange(event)}
                                                    placeholder="Rijksregister nummer*"
                                                    required
                                                    maxLength={11}
                                                    type="text"
                                                />
                                                <span className="RijksRegisterNr-error error-message"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {/* PART 03 */}
                                <div className="part" id="part03">
                                    <div className="content">
                                        <h2><span>03</span>Werk en opleiding</h2>
                                        <div className="partWrapper">
                                            <span className="School input-50 input-item">
                                                <input
                                                    name="School"
                                                    placeholder="Huidige of laatste school"
                                                    type="text"
                                                />
                                            </span>
                                            <span className="StudieRichting input-50 input-item">
                                                <input
                                                    name="StudieRichting"
                                                    placeholder="Studierichting"
                                                    type="text"
                                                />
                                            </span>
                                            <span className="ervaring input-50 input-item">
                                                <input
                                                    name="ervaring"
                                                    placeholder="Werkervaring"
                                                    type="text"
                                                />
                                            </span>
                                            <span className="WieKenJe input-100 input-item">
                                                <input
                                                    name="WieKenJe"
                                                    placeholder="Welke collega's ken je bij ons? (Scheiden met een komma)"
                                                    type="text"
                                                />
                                            </span>

                                            <h3><span>Over de job</span></h3>
                                            <span className="WelkeAfdeling input-100 input-item">
                                                <input
                                                    name="WelkeAfdeling"
                                                    onKeyDown={(event) => blockTab(event)}
                                                    placeholder="Welke afdeling geniet je voorkeur?"
                                                    type="text"
                                                />
                                            </span>

                                            <div className="choiceInternJob">
                                                <h3><span>Wanneer wens je te werken?</span></h3>
                                                <div className="checkboxGroup">
                                                    <label id="Zaterdag">
                                                        <input
                                                            className="checkWorkTime"
                                                            htmlFor="Zaterdag"
                                                            name="Zaterdag"
                                                            onChange={() => workTimeValidator()}
                                                            placeholder="Zaterdag"
                                                            type="checkbox"
                                                        />
                                                        <div className="checkbox">
                                                            <div className="checkboxFill"></div>
                                                        </div>Ik wil op zaterdagen werken
                                                    </label>
                                                    <label id="Zondag">
                                                        <input
                                                            className="checkWorkTime"
                                                            htmlFor="Zondag"
                                                            name="Zondag"
                                                            onChange={() => workTimeValidator()}
                                                            placeholder="Zondag"
                                                            type="checkbox"
                                                        />
                                                        <div className="checkbox">
                                                            <div className="checkboxFill"></div>
                                                        </div>Ik wil op zondagen werken
                                                    </label>
                                                    <label id="woensdagnamiddag">
                                                        <input
                                                            className="checkWorkTime"
                                                            htmlFor="woensdagnamiddag"
                                                            name="woensdagnamiddag"
                                                            onChange={() => workTimeValidator()}
                                                            placeholder="woensdagnamiddag"
                                                            type="checkbox"
                                                        />
                                                        <div className="checkbox">
                                                            <div className="checkboxFill"></div>
                                                        </div>Ik wil op woensdag namiddagen werken
                                                    </label>
                                                    <label id="Vakanties">
                                                        <input
                                                            className="checkWorkTime vacationInput"
                                                            htmlFor="Vakanties"
                                                            name="Vakanties"
                                                            onChange={() => { workTimeValidator(); activateDeactivateVacation() }}
                                                            placeholder="Vakanties"
                                                            type="checkbox"
                                                        />
                                                        <div className="checkbox">
                                                            <div className="checkboxFill"></div>
                                                        </div>Ik wil tijdens de vakanties werken
                                                    </label>
                                                    <span className="checkWorkTime-error error-message"></span>
                                                </div>
                                                <div className="choiceVacationPeriod" display="none">
                                                    <h3><span>Je koos: werken tijdens de vakanties</span></h3>
                                                    <p>
                                                        Werken tijdens de vakanties: specifieer hier met begin- en einddatum
                                                        welke periodes je zou willen werken.
                                                    </p>
                                                    <div className="vacationDate">
                                                        <div className="dateGroup">
                                                            <select name="begindag1" onChange={() => datePeriodValidator()}></select>
                                                            <select name="beginmaand1" onChange={() => datePeriodValidator()}></select>
                                                            <select name="beginjaar1" onChange={() => datePeriodValidator()}></select>
                                                        </div>
                                                        <span>tot en met</span>
                                                        <div className="dateGroup">
                                                            <select name="einddag1" onChange={() => datePeriodValidator()}></select>
                                                            <select name="eindmaand1" onChange={() => datePeriodValidator()}></select>
                                                            <select name="eindjaar1" onChange={() => datePeriodValidator()}></select>
                                                        </div>
                                                    </div>
                                                    <div className="periodeKnop" onClick={() => addPeriod()}>Voeg periode toe</div>
                                                    <div className="vacationDateExtra" style={{ "display": "none" }}>
                                                        <div className="dateGroup">
                                                            <select name="begindag2" onChange={() => datePeriodValidator()}></select>
                                                            <select name="beginmaand2" onChange={() => datePeriodValidator()}></select>
                                                            <select name="beginjaar2" onChange={() => datePeriodValidator()}></select>
                                                        </div>
                                                        <span>tot en met</span>
                                                        <div className="dateGroup">
                                                            <select name="einddag2" onChange={() => datePeriodValidator()}></select>
                                                            <select name="eindmaand2" onChange={() => datePeriodValidator()}></select>
                                                            <select name="eindjaar2" onChange={() => datePeriodValidator()}></select>
                                                        </div>

                                                        <div className="periodeKnop" onClick={() => addPeriod2()}>Voeg nog een periode toe</div>
                                                        <div className="vacationDateExtra2" style={{ "display": "none" }}>
                                                            <div className="dateGroup">
                                                                <select name="begindag3" onChange={() => datePeriodValidator()}></select>
                                                                <select name="beginmaand3" onChange={() => datePeriodValidator()}></select>
                                                                <select name="beginjaar3" onChange={() => datePeriodValidator()}></select>
                                                            </div>
                                                            <span>tot en met</span>
                                                            <div className="dateGroup">
                                                                <select name="einddag3" onChange={() => datePeriodValidator()}></select>
                                                                <select name="eindmaand3" onChange={() => datePeriodValidator()}></select>
                                                                <select name="eindjaar3" onChange={() => datePeriodValidator()}></select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="vacationDate-error error-message"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* PART 04 */}
                                <div className='part' id='part04'>
                                    <div className='content'>
                                        <h2><span>04</span>Afronding</h2>
                                        <div className='partWrapper'>
                                            <div className='checkboxGroup'>
                                                <label id='akkoord'>
                                                    <input htmlFor='akkoord' type="checkbox" name="langer" value="akkoord" />
                                                    <div className='checkbox'>
                                                        <div className='checkboxFill'></div>
                                                    </div>
                                                    <span>
                                                        Als er momenteel geen vacature is ben ik akkoord dat mijn gegevens
                                                        gedurende 2 jaar worden bewaard in de wervingsreserve.
                                                    </span>
                                                </label>
                                                <label id='chkAgree'>
                                                    <input onChange={() => applicantAgreed()} htmlFor='chkAgree' type="checkbox" name="chkAgree" autoComplete="off" required />
                                                    <div className='checkbox'>
                                                        <div className='checkboxFill'></div>
                                                    </div>
                                                    <span>
                                                        Ik heb de <a href="https://www.superplanner.eu/ADZedelgem/privacy.asp">privacy verklaring</a> gelezen en
                                                        begrijp mijn rechten met betrekking tot het bewaren van mijn gegevens.
                                                    </span>
                                                </label>
                                            </div>
                                            <button id="ok" name="ok" onClick={() => checkRequired()} type="submit"
                                                value="voerin"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="button disabled button_nav" onClick={() => goToPrev()}>Keer terug</div>
                            <div className="button button_nav" onClick={() => goToNext()}>Ga verder</div>
                        </form>
                        <div className="formMessage"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Superplanner