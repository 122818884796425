import React, { useEffect } from 'react'
import { useSelector } from "react-redux";

const News = () => {

    const { lang, translate, folder_name } = useSelector((state) => state.AdminReducer);

    return (
        <section id="news">
            <div className='content'>
                <h3>“AANGENAME SFEER AAN HET ONTHAAL DANKZIJ HET LOTTOVERKOOPPUNT”</h3>
                <h4>AD Delhaize Opwijk genereert meer omzet met de producten van de Nationale Loterij</h4>
                <p>
                    Een Lotto-verkooppunt in de supermarkt brengt niet alleen een omzetstijging met zich mee, er zijn nog een heleboel andere voordelen aan verbonden als retailer. Er staat geen termijn op de producten van de Nationale Loterij waardoor er geen risico op verlies is en bovendien gebeurt de bestelling en levering van loterijproducten volledig automatisch wat heel wat administratieve rompslomp bespaart. “Maar het belangrijkste van alles is de aangename sfeer aan mijn onthaal”, aldus zaakvoerder van AD Delhaize Opwijk Dieter Debaveye. “Nog nooit is een lottospeler slechtgezind mijn winkel buiten gewandeld.”
                </p>
            </div>
            <div className='video'>
                <video controls>
                    <source src={require('../library/loterij.mp4').default} />
                </video>
            </div>
            {/* BLOCK 1 */}
            <div className='content block one'>
                <div className='container'>
                    <span>
                        <h3>DYNAMIEK AAN HET ONTHAAL</h3>
                        <h4>Op eigen initiatief</h4>
                    </span>
                    <p>AD Delhaize in Opwijk deed de deuren open op 13 april 2017. Amper twee maanden later was er ook een loterijverkooppunt geïnstalleerd in de gloednieuwe winkel. “Ik had mijn aanvraag al gedaan voor de opening van onze winkel”, vertelt zaakvoerder Dieter Debaveye. “De voornaamste reden waarom ik die aanvraag had ingediend, was omdat ik graag wat dynamiek wou creëren aan het onthaal. Dat kan enerzijds door een een leveringslocatie te worden om mee te stappen in het online verhaal, of door een lottoverkooppunt op te zetten anderzijds. De retour van pakjes schrikte me echter af en ik wou helemaal geen onthaal dat na verloop van tijd een opslagplaats van pakketjes zou worden. Daarom was ik al vrij snel overtuigd en heb ik op eigen initiatief contact opgenomen met de Nationale Loterij.”</p>
                    <q>"Loterijproducten zijn een toegevoegde waarde voor eender welke supermarkt"</q>
                </div>
                <figure>
                    <img src={require('../library/images/news-foto1.jpg').default} />
                </figure>
            </div>
            {/* BLOCK 2 */}
            <div className='content block two'>
                <div className='container half'>
                    <h4>Toegevoegde waarde</h4>
                    <p>Dieter is ervan overtuigd dat het aanbieden van loterijproducten een toegevoegde waarde is voor eender welke verkooppunt. “Hoe je het ook draait of keert, je genereert er sowieso een omzetstijging mee. Mensen komen vaak binnen enkel en alleen voor de Lotto, EuroMillions of een krasspel. De drijfveer daarachter is dat eens ze in de supermarkt zijn, ze geneigd zullen zijn ook nog vlug in de winkel binnen te springen en zo genereer je meerverkoop. Maar wat ik het belangrijkste vind, is de aangename sfeer die je ermee creëert aan het onthaal, vaak een punt waar mensen langskomen wanneer iets fout gelopen is. Een lottospeler daarentegen is altijd goedgezind, of die nu wint of niet.”</p>
                    <q>"Verkoop piekt tijdens eindejaarsperiode"</q>
                    <br />
                    <h4>Ondersteuning</h4>
                    <p>“De Nationale Loterij ondersteunt ons in allerlei communicatie”, gaat Dieter verder. “We worden regelmatig gebrieft en krijgen meldingen binnen wanneer nieuwe producten gelanceerd worden. Maar ook naar promotiemiddelen en POS-materiaal toe krijgen we alles altijd tijdig binnen per post.”</p>
                </div>
                <div className='container half'>
                    <h4>Kadoosjes en kadobomen scoren</h4>
                    <p>Dat de verkoop rond de eindejaarsperiode stijgt, is geen geheim. Ook Dieter merkt dat er in zijn winkel meer gekocht wordt naar het einde van het jaar toe. Vooral Kadoosjes en kadobomen gaan als zoete broodjes over de toonbank. “Mensen weten rond deze periode vaak niet wat gekocht als geschenk en dan kunnen ze met zo’n doosje vol krasloten wel uitpakken bij vrienden of familie”, aldus Dieter. “Kadoosjes en kadobomen kunnen aangepast worden naar eender welk budget, dat maakt het een uniek geschenk.” Los van alles merkt Dieter dat hoe hoger de jackpot bij Lotto, EuroMillions, Joker+, Keno of Pick3, hoe meer er gespeeld wordt. “Ook bijkomende trekkingen zoals bijvoorbeeld de Extra Lotto-trekking van vrijdag de 13de scoren goed en worden altijd bovenop de gewone Lotto gekocht, dus dat is een mooie surplus.”</p>
                    <q>"De winkelpunten zijn een van onze belangrijkse communicatiemiddelen"</q>
                </div>
            </div>
            {/* BLOCK 3 */}
            <div className='content block three'>
                <figure>
                    <img src={require('../library/images/news-foto2.jpeg').default} />
                </figure>
                <div className='container'>
                    <span>
                        <h4>Klantenbinding</h4>
                    </span>
                    <p>De Nationale Loterij communiceert naar haar spelers via allerlei kanalen. “Naast de klassieke en sociale media hebben we een social media ambassadeursprogramma waarbij retailers in 2 klikken kant-en-klare promotieboodschappen kunnen delen op de facebookpagina van hun winkel ”, vervolgt Miguel Ledesma, regional HUB manager bij de Nationale Loterij. “Maar een van de belangrijkste communicatiemiddelen zijn de winkelpunten. Het is daar dat onze affiches uithangen en dat klanten op de hoogte gebracht worden van campagnes en extra trekkingen. Ook Lottery Club is een voordelenprogramma en handige tool naar klantenbinding toe. Elke consument die lid is, krijgt maandelijks allerlei voordelen zoals kortingen of een gratis krasbiljet. Dat stuurt mensen opnieuw richting hun lottoverkooppunt.”</p>
                </div>
            </div>


        </section>
    )
}

export default News